<template>
    <section class="view online-shopping-terms">
        <view-title v-bind:title="$t('message.online-shopping-terms.title')"></view-title>
        <div class="container py-5">
            <div class="row">
                <div class="col-md-3">
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'en'">
                        <li class="nav-item">
                            <a class="nav-link" href="#secure-shopping-guarantee">Secure shopping guarantee</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#terms-of-use">Terms of Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#compliance-with-laws">Compliance with laws</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#use-of-forwarder">Use of forwarder</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#free-shipping">Free shipping</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#trademarks-serivce-marks-and-copyright">Our trademarks, service marks and copyright</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#resale-purpose-not-permitted">Resale Purpose is Not Permitted</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#title-of-products-documents-and-fees-to-import">Title of Products, Documents and Fees to Import</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#indemnity-and-limitation-of-liability">Indemnity and Limitation of Liability</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#our-privacy-policy">Our Privacy Policy</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#modification-of-terms">Modification of Terms</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#governing-law">Governing Law</a>
                        </li>
                    </ul>
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'zh-hk'">
                        <li class="nav-item">
                            <a class="nav-link" href="#secure-shopping-guarantee">安全購物保證</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#terms-of-use">使用條款</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#compliance-with-laws">遵守法律</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#use-of-forwarder">使用貨運服務</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#free-shipping">免費送貨</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#trademarks-serivce-marks-and-copyright">我們的商標、服務標誌及版權</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#resale-purpose-not-permitted">禁止轉售</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#title-of-products-documents-and-fees-to-import">產品所有權、進口文件及費用</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#indemnity-and-limitation-of-liability">賠償及責任限制</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#our-privacy-policy">我們的私隱政策</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#modification-of-terms">條款修改</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#governing-law">法律監管</a>
                        </li>
                    </ul>
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'zh-cn'">
                        <li class="nav-item">
                            <a class="nav-link" href="#secure-shopping-guarantee">安全购物保证</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#terms-of-use">使用条款e</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#compliance-with-laws">遵守法律</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#use-of-forwarder">使用货运服务</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#free-shipping">免费送货</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#trademarks-serivce-marks-and-copyright">我们的商标、服务标志及版权</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#resale-purpose-not-permitted">禁止转售</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#title-of-products-documents-and-fees-to-import">
                                产品所有权、进口文件及费用
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#indemnity-and-limitation-of-liability">
                                赔偿及责任限制
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#our-privacy-policy">我们的私隐政策</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#modification-of-terms">条款修改</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#governing-law">法律监管</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'en'">
                    <h5 id="secure-shopping-guarantee">Secure shopping guarantee</h5>
                    <p class="text-justify">
                        Rest assured that giving your credit card number over the Web with marier Skincare is safe and secure. We use our best efforts to protect your personal information. marierskincare.com stores all customer data (name, address, credit card number, order information, etc.) on our own dedicated, secure servers to protect against unauthorized access. All your personal information inputted (including credit card number, name, address and  password) by you is encrypted during transmission to ensure all your details are kept secure.
                    </p>
                    <p class="text-justify">
                        You may also help us out in maintaining the security of our site. Remember to exit out of marierskincare.com before leaving your computer unattended so that others cannot see any of your personal information and in the event that your credit card is stolen or misplaced, contact your credit card provider immediately.
                    </p>

                    <h5 id="terms-of-use">Terms of Use</h5>
                    <p class="text-justify">
                        Welcome to marierskincare.com, the leading online one-stop shop for all your cosmetics, skincare, fragrances, haircare and other personal care needs. To ensure a safe, enjoyable environment for all of our users, we have established our Terms of Use. They spell out what you can expect from us and what we expect from you. Please note that by accessing any area of marierskincare.com, you agree to be bound and to abide by these Terms of Use. We also strongly advise you to see our full privacy policy statement. marierskincare.com is owned and operated by I-Skincare Company Limited. Any reference in these Terms of Use to marierskincare.com shall include reference to I-Skincare Company Limited and their respective employees and agents.
                    </p>

                    <h5 id="compliance-with-laws">Compliance with laws</h5>
                    <p class="text-justify">
                        I-Skincare Company Limited is a company incorporated under the laws of Hong Kong. We operate in Hong Kong and comply with all the laws and regulations of Hong Kong. All our products are in compliance with all the requirements and specifications under the laws of Hong Kong. We are not in a position to guarantee compliance with specific local requirements or specifications under the local jurisdictions of customers.
                    </p>

                    <h5 id="use-of-forwarder">Use of forwarder</h5>
                    <p class="text-justify">
                        We recommend delivery services for shipping of the products you ordered. Your rights regarding the delivery of the products shall be governed which will be available for perusal upon request. By choosing the forwarder we recommend, you agree that we will appoint such forwarder to handle all the aspects of the delivery on your behalf. We will not be responsible for any act or failure to act on the part of such forwarder. We disclaim any liability whatsoever and you assume all risks and liabilities associated with its use.
                    </p>

                    <h5 id="free-shipping">Free shipping</h5>
                    <p class="text-justify">
                        In case you choose to use the forwarder that we recommended and your order reaches a particular Net Order Amount, we will pay the shipping fee to the forwarder on your behalf. Your order will be shipped by the shipping method specified for free.
                    </p>

                    <h5 id="trademarks-serivce-marks-and-copyright">Our trademarks, service marks and copyright</h5>
                    <p class="text-justify">
                        The following trademarks and service marks belong to I-Skincare Co. Ltd or its affiliates and all rights in them are reserved: marier Skincare, I-Skincare Co. Ltd, marierskincare.com. These and other marier Skincare or marierskincare.com graphics, logos, service marks and trademarks of I-Skincare Co. Ltd and its affiliates and subsidiaries may not be used without the prior written consent of I-Skincare Co. Ltd or its affiliates or subsidiaries. All other trademarks, product names, company names and logos appearing on marierskincare.com are the property of their respective owners. All content on our site, including design, text, graphics, logos, button icons, images, software and audio clips; any improvements or modifications to such content; any derivative works thereof; the collection, arrangement and assembly of all content appearing on marierskincare.com, are the exclusive property of marierskincare.com and are protected by Hong Kong and international copyright and other applicable intellectual property laws. All software used on our site is the property of marierskincare.com or its software suppliers and is protected by Hong Kong and international copyright and other applicable intellectual property laws.
                    </p>

                    <h5 id="resale-purpose-not-permitted">Resale Purpose is Not Permitted</h5>
                    <p class="text-justify">
                        All products sold are for customers' use only and shall not be commercially resold. marierskincare.com may refuse any purchasing order which it suspects is for commercial resale.
                    </p>

                    <h5 id="title-of-products-documents-and-fees-to-import">Title of Products, Documents and Fees to Import</h5>
                    <p class="text-justify">
                        All title and risk of loss and damage of products will be passed to customer when order is dispatched by marierskincare.com. marierskincare.com will deliver the products according to customer’s instructions. For the delivery to the countries or cities outside of Hong Kong, you may be required to pay customs and duties, or to obtain certain licence or apply for registration of your purchased products according to the regulations or requirements of the delivery destination or stopover or transfer point. Such fee/charge and the licence or registration requirement are determined according to the regulations or requirements of the delivery destination or stopover or transfer point and the same is beyond the control of marierskincare.com and cannot be foreseen by marierskincare.com. As such, marierskincare.com will not pay, nor be held responsible, for any such fee/charge, or the licence or registration. Should the product be seized by the authority of the destination or stopover or transfer point during the delivery, customer should be responsible to any loss incurred. marierskincare.com will not be responsible to the same under any circumstance. By continuing to complete your order, you agree to the terms and conditions herein and agree to be responsible for any customs or duties or any registration or licence requirements. If the product package is under any circumstances be rejected and returned to marierskincare.com, the title of the products will be transferred to marierskincare.com.
                    </p>

                    <h5 id="indemnity-and-limitation-of-liability">Indemnity and Limitation of Liability</h5>
                    <p class="text-justify">
                        You agree to indemnify, defend and hold harmless marierskincare.com from and against all losses, expenses, damages and costs (including reasonable legal fees) arising from any breach of these Terms of Use or any other activity by you in connection with your use of the site. You further agree that marierskincare.com shall not be held liable howsoever for your use of the site and you agree to waive all claims that you may have against marierskincare.com for any loss or damages you may sustain in connection with your use of the site. In the event that this disclaimer is unenforceable, the total liability of marierskincare.com.
                    </p>

                    <h5 id="our-privacy-policy">Our Privacy Policy</h5>
                    <p class="text-justify">
                        You agree to our use of information about you as described in <router-link to="/privacy">our privacy policy</router-link>.
                    </p>

                    <h5 id="modification-of-terms">Modification of Terms</h5>
                    <p class="text-justify">
                        These Terms of Use (including the order terms contained in the Help page) may be modified from time to time. You should therefore read these Terms of Use and the order terms before you place any order.
                    </p>

                    <h5 id="governing-law">Governing Law</h5>
                    <p class="text-justify">
                        These Terms of Use (including the order terms contained in the Help page) shall be governed by and construed in accordance with the laws of Hong Kong Special Administrative Region. You irrevocably agree that the courts of Hong Kong Special Administrative Region shall have exclusive jurisdiction in relation to any claim or dispute concerning or arising from these Terms of Use and the order terms.
                    </p>

                    <p>These Terms of Use were last updated on January 2018</p>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-hk'">
                    <h5 id="secure-shopping-guarantee">安全購物保證</h5>
                    <p class="text-justify">
                        我們確保您的所有個人資料及信用咭資料得到最安全並高度保密的處理，marier Skincare 曼肌兒 網站會將所有的顧客資料（包括姓名、地址、信用咭號碼及訂單資料等）儲存在周密安全，並且屬於私人及隔離的伺服器，以確保資料不會在未被允許的情況下被讀取。同時為向客戶提供高度安全的網上購物平台，所有上傳的個人資料（包括姓名、地址、信用咭號碼及密碼均經加密機制處理，以保障您資料傳送時的安全。我們亦建議您一同參與，維護網上購物安全，緊記每次完成購物或離開使用中的電腦時，必須按「登出」按鈕，以防止其他使用者獲取您的個人資料。如遇信用卡被盜用，請馬上與發卡銀行聯絡以採取相關行動。
                    </p>

                    <h5 id="terms-of-use">使用條款</h5>
                    <p class="text-justify">
                        歡迎瀏覽marierskincare.com，為了使你獲得安全和愉快的購物經驗，我們列明以下的使用條款， 使你更明白我們對雙方的期望。請留意，當你瀏覽marierskincare.com任何一個網頁，代表你已接受並遵守這些條款。我們更建議你仔細閱讀我們的私隱政策聲明。marierskincare.com是由I-Skincare Co. Ltd擁有及營運，任何有關marierskincare.com的使用條款也適用於I-Skincare Co. Ltd、其員工及代理公司。
                    </p>

                    <h5 id="compliance-with-laws">遵守法律</h5>
                    <p class="text-justify">
                        I-Skincare Co. Ltd乃根據香港法律成立的公司。本公司於香港營運並符合所有香港法律和法規。本公司提供的所有產品均符合香港所有法律的要求及規格。本公司提供的所有產品均符合香港所有法律的要求及規格。我們不能確保該等產品符合顧客所在地特定之法律要求及規格。有關訂單的送貨服務，本公司有建議的貨運服務公司。本公司產品及運輸費以港幣計算。所有訂單均以港幣進行交易。
                    </p>

                    <h5 id="use-of-forwarder">使用貨運服務</h5>
                    <p class="text-justify">
                        有關訂單的送貨服務，本公司有建議的送貨服務。閣下在貨品運送方面的權利受相關貨運公司的條款及細則所約束。如閣下選用本公司建議的貨運公司，就代表同意本公司委託該公司為你處理一切有關貨運事宜。本公司不會就相關貨運公司的任何行為或失當而負責。本公司就此聲明，不會承擔閣下使用相關貨運公司引致的風險及責任。
                    </p>

                    <h5 id="free-shipping">免費送貨</h5>
                    <p class="text-justify">
                        如閣下的訂單達到要求之訂單總淨值，本公司將會代為支付運費。閣下之該訂單可享本公司指定的免費送貨服務。
                    </p>

                    <h5 id="trademarks-serivce-marks-and-copyright">我們的商標、服務標誌及版權</h5>
                    <p class="text-justify">
                        以下的商標及服務標誌(marier Skincare、曼肌兒)是屬於I-Skincare Co. Ltd及聯營公司及附屬公司， 我們保留所有追討的權利。marierskincare.com內所有圖片、設計、標誌、服務標誌及商標，皆不可在沒有I-Skincare Co. Ltd或聯營公司或附屬公司的允許下作任何的用途。此外，所有出現在marierskincare.com的商標、產品名稱、公司名稱及標誌皆是I-Skincare Co. Ltd及其個別擁有人的專屬財產。 所有marierskincare.com的內容包括設計、文章、圖片、標誌、按鈕鍵、形象、軟件、聲音資料的任何改動、抽取、收集、重組及集結皆受香港及國際版權業及任何可引用的知識產權法例所保護。所有使用於marierskincare.com的軟件皆屬於marierskincare.com或其提供者的財產，並受香港及國際版權業協會及任何 可適用的知識產權法例所保護。
                    </p>

                    <h5 id="resale-purpose-not-permitted">禁止轉售</h5>
                    <p class="text-justify">
                        所有marierskincare.com的產品皆是銷售給用家，不得作任何商業轉售，若我們對訂購產品的用途有任何懷疑， 將拒絕發售該產品。
                    </p>

                    <h5 id="title-of-products-documents-and-fees-to-import">產品所有權、進口文件及費用</h5>
                    <p class="text-justify">
                        當marierskincare.com把產品寄出時，產品之所有權及減失風險將由marierskincare.com轉移至閣下。marierskincare.com會按閣下之要求遞送產品。若產品運送到香港以外之地區或國家，你可能需要繳付附加費、稅項，或就有關產品向運送目的地或轉運地登記或申請進口許可證，這些收費及文件申領均視乎運送目的地的法例而定，marierskincare.com不能控制或預計這類收費的數目，marierskincare.com亦不會為該等款項或文件申領手續負上任何法律責任。如產品在運輸過程中被目的地及運轉地的相關主管部門扣留，您將自行承擔相應損失，marierskincare.com不因此承擔任何責任。如閣下完成有關之訂購程序，即表示接受條款，並為運送訂購產品的一切附加費、稅項或進口文件申領事宜負責。如最後產品遭退回，產品之所有權回轉至marierskincare.com。
                    </p>

                    <h5 id="indemnity-and-limitation-of-liability">賠償及責任限制</h5>
                    <p class="text-justify">
                        若你在使用marierskincare.com時，因閣下違反訂購條款或任何行動而令marierskincare.com招致損失，你同意賠償及彌償marierskincare.com的損失、支出、毀壞及費用（包括合理的法律費用）。你同意marierskincare.com不會為你因使用本網站而招致的損失，負上任何法律責任，也同意放棄追究marierskincare.com任何賠償。
                    </p>

                    <h5 id="our-privacy-policy">我們的私隱政策</h5>
                    <p class="text-justify">
                        閣下同意我們按<router-link to="/privacy">私隱條款</router-link>所列明的用途，使用你的資料。
                    </p>

                    <h5 id="modification-of-terms">條款修改</h5>
                    <p class="text-justify">
                        這些使用條款（包括支援內的訂購條款）將會在有需要的時侯作出修改，故閣下在本網站購物之前，請先詳細閱讀使用條款及線上購物條款。
                    </p>

                    <h5 id="governing-law">法律監管</h5>
                    <p class="text-justify">
                        這些使用條款（包括支援內的訂購條款）將受香港的法律監管及按香港特別行政區的法律理解，你同意香港特別行政區法庭對於任何關於使用條款及訂購條款的爭論及追討有專有的審判權。 網站中的中英內容(包括所有私隱政策及使用條款)如有任何差異，概以英文版本為準。
                    </p>

                    <p>使用條款更新於 2018 年 1 月 31 日。</p>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-cn'">
                    <h5 id="secure-shopping-guarantee">安全购物保证</h5>
                    <p class="text-justify">
                        我们确保您的所有个人资料及信用咭资料得到最安全并高度保密的处理，marier Skincare 曼肌兒 网站会将所有的顾客资料（包括姓名、地址、信用咭号码及订单资料等）储存在周密安全，并且属于私人及隔离的伺服器，以确保资料不会在未被允许的情况下被读取。同时为向客户提供高度安全的网上购物平台，所有上传的个人资料（包括姓名、地址、信用咭号码及密码均经加密机制处理，以保障您资料传送时的安全。我们亦建议您一同参与，维护网上购物安全，紧记每次完成购物或离开使用中的电脑时，必须按「登出」按钮，以防止其他使用者获取您的个人资料。如遇信用卡被盗用，请马上与发卡银行联络以采取相关行动。
                    </p>

                    <h5 id="terms-of-use">使用条款</h5>
                    <p class="text-justify">
                        欢迎浏览marierskincare.com，为了使你获得安全和愉快的购物经验，我们列明以下的使用条款， 使你更明白我们对双方的期望。请留意，当你浏览marierskincare.com任何一个网页，代表你已接受并遵守这些条款。我们更建议你仔细阅读我们的私隐政策声明。 marierskincare.com是由I-Skincare Co. Ltd拥有及营运，任何有关marierskincare.com的使用条款也适用于I-Skincare Co. Ltd、其员工及代理公司。
                    </p>

                    <h5 id="compliance-with-laws">遵守法律</h5>
                    <p class="text-justify">
                        I-Skincare Co. Ltd乃根据香港法律成立的公司。本公司于香港营运并符合所有香港法律和法规。本公司提供的所有产品均符合香港所有法律的要求及规格。本公司提供的所有产品均符合香港所有法律的要求及规格。我们不能确保该等产品符合顾客所在地特定之法律要求及规格。有关订单的送货服务，本公司有建议的货运服务公司。本公司产品及运输费以港币计算。所有订单均以港币进行交易。
                    </p>

                    <h5 id="use-of-forwarder">使用货运服务</h5>
                    <p class="text-justify">
                        有关订单的送货服务，本公司有建议的送货服务。阁下在货品运送方面的权利受相关货运公司的条款及细则所约束。如阁下选用本公司建议的货运公司，就代表同意本公司委托该公司为你处理一切有关货运事宜。本公司不会就相关货运公司的任何行为或失当而负责。本公司就此声明，不会承担阁下使用相关货运公司引致的风险及责任。
                    </p>

                    <h5 id="free-shipping">免费送货</h5>
                    <p class="text-justify">
                        如阁下的订单达到要求之订单总净值，本公司将会代为支付运费。阁下之该订单可享本公司指定的免费送货服务。
                    </p>

                    <h5 id="trademarks-serivce-marks-and-copyright">我们的商标、服务标志及版权</h5>
                    <p class="text-justify">
                        以下的商标及服务标志(marier Skincare、曼肌兒)是属于I-Skincare Co. Ltd及联营公司及附属公司， 我们保留所有追讨的权利。 marierskincare.com内所有图片、设计、标志、服务标志及商标，皆不可在没有I-Skincare Co. Ltd或联营公司或附属公司的允许下作任何的用途。此外，所有出现在marierskincare.com的商标、产品名称、公司名称及标志皆是I-Skincare Co. Ltd及其个别拥有人的专属财产。所有marierskincare.com的内容包括设计、文章、图片、标志、按钮键、形象、软件、声音资料的任何改动、抽取、收集、重组及集结皆受香港及国际版权业及任何可引用的知识产权法例所保护。所有使用于marierskincare.com的软件皆属于marierskincare.com或其提供者的财产，并受香港及国际版权业协会及任何 可适用的知识产权法例所保护。
                    </p>

                    <h5 id="resale-purpose-not-permitted">禁止转售</h5>
                    <p class="text-justify">
                        所有marierskincare.com的产品皆是销售给用家，不得作任何商业转售，若我们对订购产品的用途有任何怀疑， 将拒绝发售该产品。
                    </p>

                    <h5 id="title-of-products-documents-and-fees-to-import">产品所有权、进口文件及费用</h5>
                    <p class="text-justify">
                        当marierskincare.com把产品寄出时，产品之所有权及减失风险将由marierskincare.com转移至阁下。 marierskincare.com会按阁下之要求递送产品。若产品运送到香港以外之地区或国家，你可能需要缴付附加费、税项，或就有关产品向运送目的地或转运地登记或申请进口许可证，这些收费及文件申领均视乎运送目的地的法例而定，marierskincare.com不能控制或预计这类收费的数目，marierskincare.com亦不会为该等款项或文件申领手续负上任何法律责任。如产品在运输过程中被目的地及运转地的相关主管部门扣留，您将自行承担相应损失，marierskincare.com不因此承担任何责任。如阁下完成有关之订购程序，即表示接受条款，并为运送订购产品的一切附加费、税项或进口文件申领事宜负责。如最后产品遭退回，产品之所有权回转至marierskincare.com。
                    </p>

                    <h5 id="indemnity-and-limitation-of-liability">赔偿及责任限制</h5>
                    <p class="text-justify">
                        若你在使用marierskincare.com时，因阁下违反订购条款或任何行动而令marierskincare.com招致损失，你同意赔偿及弥偿marierskincare.com的损失、支出、毁坏及费用（包括合理的法律费用）。你同意marierskincare.com不会为你因使用本网站而招致的损失，负上任何法律责任，也同意放弃追究marierskincare.com任何赔偿。
                    </p>

                    <h5 id="our-privacy-policy">我们的私隐政策</h5>
                    <p class="text-justify">
                        阁下同意我们按<router-link to="/privacy">私隐条款</router-link>所列明的用途，使用你的资料。
                    </p>

                    <h5 id="modification-of-terms">条款修改</h5>
                    <p class="text-justify">
                        些使用条款（包括支援内的订购条款）将会在有需要的时侯作出修改，故阁下在本网站购物之前，请先详细阅读使用条款及线上购物条款。
                    </p>

                    <h5 id="governing-law">法律监管</h5>
                    <p class="text-justify">
                        这些使用条款（包括支援内的订购条款）将受香港的法律监管及按香港特别行政区的法律理解，你同意香港特别行政区法庭对于任何关于使用条款及订购条款的争论及追讨有专有的审判权。网站中的中英内容(包括所有私隐政策及使用条款)如有任何差异，概以英文版本为准。
                    </p>

                    <p>使用条款更新于 2018 年 1 月 31 日。</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ViewTitle from "../components/ViewTitle";
    export default {
        name: "OnlineShoppingTerms",
        components: {ViewTitle}
    }
</script>

<style lang="scss" scoped>
    .online-shopping-terms {
        ul.nav {
            top: 65px;
        }
    }
</style>
